<template>
  <section :class="$style.thankyou">
    <img
      :class="$style.thankyou__logo"
      src="@/assets/images/logo.png"
      alt="logo"
    />
    <Title :class="$style.thankyou__title" position="center">
      Спасибо! Выполните, пожалуйста, это действие:
    </Title>
    <p :class="$style.thankyou__text">
      Нажмите на кнопку ниже, чтобы запустить телеграм-бот. Он поможет
      <br />
      Вам зарегистрироваться и попасть в инвест-сообщество
    </p>
    <Button @click="goTo">Запустить бот</Button>
  </section>
</template>

<script>
import Title from '@/components/basic/Title'
import Button from '@/components/basic/Button'

export default {
  components: {
    Title,
    Button
  },
  methods: {
    goTo() {
      location.href = 'https://t.me/RegistrationMI_bot'
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/breakpoints.scss';

.thankyou {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 1.5rem;

  &__title {
    margin: 2rem 0;
  }

  &__text {
    margin: 0 0 2rem;
    @include subtitle();
    text-align: center;
    line-height: 3.5rem;
  }
}

@include for-mobile {
  .thankyou {
    padding: 3rem 1.5rem;

    &__text {
      font-size: 1.25rem;
      line-height: 2rem;
    }
  }
}
</style>
