<template>
  <div :class="[$style.title, $style[position], $style[color]]"><slot /></div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    position: String,
    color: String
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/colors.scss';

.title {
  @include title();

  &.center {
    text-align: center;
  }

  &.white {
    color: $white;
  }
}
</style>
