<template>
  <button :class="[$style.button, $style[type]]" @click="$emit('click')">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/mixins.scss';

.button {
  padding: 0.9rem 1.5rem;
  outline: none;
  border: none;
  border-radius: 1rem;
  background: $blue;
  box-shadow: 0 1rem 1.5rem rgba(16, 129, 232, 0.16);
  @include button();
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.9;
  }

  &.primary {
    padding: 0;
    background: transparent;
    color: $grey;
    box-shadow: none;
  }
}
</style>
